import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap/all';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import download from '@/lottieFiles/download.json';

//* Style
import GuidesSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';

const GuidesSection = ({ data }) => {
	//! Refs
	const titleRef = useRef();
	const descriptionRef = useRef();
	const buttonRef = useRef();

	//! State
	const [animStart, setAnimStart] = useState(false);

	useEffect(() => {
		if (animStart) {
			gsap.to(descriptionRef.current, {
				opacity: 1,
				ease: 'power2.out',
				duration: 1.2,
				delay: 0.2,
			});

			gsap.to(buttonRef.current, {
				delay: 0.2 + 0.15,
				opacity: 1,
				duration: 1.2,
				ease: 'power2.out',
			});
			gsap.to(
				buttonRef.current.children[1],

				{
					delay: 0.2 + 0.15,
					scale: 1,
					duration: 1.2,
					ease: 'power2.out',
				}
			);
		}
	}, [animStart]);

	//! Dowland function
	const handleDownload = useCallback((id) => {
		const link = `${process.env.NEXT_PUBLIC_APP_API_SERVER}/download-file/${id}`;

		window.location.href = link;
	}, []);

	//! Every Item
	const everyGuid = useMemo(() => {
		return data?.documents[0]?.resources.map((item, i) => {
			return (
				<div
					key={i}
					className='every-guide'>
					<Text className='p2 font-montserrat-medium blue-1000-color'>{item.title}</Text>
					<div className='dowland-wrapper'>
						<Button
							onClick={() => handleDownload(item.file.id)}
							className={'dowland-button '}
							btnType={'lottie'}
							lottieFiles={download}>
							<span className={'font-montserrat-medium p5 uppercase'}>
								{item.additional_info.language}&nbsp;&nbsp;|&nbsp;&nbsp;
								{item?.file?.type === 'pdf' ? 'PDF' : 'IMAGE'}
								&nbsp;&nbsp;
							</span>
						</Button>
					</div>
				</div>
			);
		});
	});

	return (
		<GuidesSectionStyle>
			<Container
				isSection
				className={'row'}>
				<div className='right-section col-5 col-t-12'>
					<ScrollActive
						animTitle
						allRefs={titleRef}
						percent
						percentDuration={30}
						percentFunction={setAnimStart}>
						<Text
							ref={titleRef}
							tag={'h2'}
							className={'h2 font-montserrat-medium blue-1000-color title uppercase opacity-0'}>
							{data.title}
						</Text>
					</ScrollActive>

					<Text
						ref={descriptionRef}
						className={'p2 font-active-grotesk-normal blue-1000-color description opacity-0'}>
						{data.description}
					</Text>

					<div
						className='button-wrapper opacity-0'
						ref={buttonRef}>
						<Button
							className={'button-real'}
							btnType='oval'
							text={'explore'}
							url={`${config.routes.usefulInfo.path}maps/` ? `${config.routes.usefulInfo.path}maps/` : ''}
						/>
						<Button
							btnType={'oval'}
							className='div-scaled-button reverse '>
							.
						</Button>
					</div>
				</div>

				<div className='col-1' />

				<div className='left-section col-6 col-t-12'>{everyGuid}</div>
			</Container>
		</GuidesSectionStyle>
	);
};

export default GuidesSection;
