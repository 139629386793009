import { useMemo, useRef } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Style
import MobileHomeRegionStyle from './style';

//* Components
import SvgRegion from '../SvgRegion';
import Text from '@/components/global/Text';
import CustomImage from '@/components/global/Image';
import Container from '@/components/global/Container';
import CustomLink from '@/components/global/CustomLink';
import ScrollActive from '@/components/global/ScrollActive';

const MobileHomeRegion = ({ data, translate, title = 'exploreTheRegions' }) => {
	//! Ref
	const titleRef = useRef();

	const everRegion = useMemo(() => {
		return data.map((item, i) => {
			return (
				<div
					key={i}
					className='wrapperItemSlider-mobile'>
					<CustomLink
						key={i}
						url={`${config.routes.regions.path}${item.slug}`}>
						<div className='wrapper-item-slider-mobile'>
							<CustomImage
								src={item.featured_image.src}
								className={`everSlide-mobile`}
							/>

							<div className='wrapper-info-mobile'>
								<div className='title-wrapper-mobile'>
									<Text className={'h5 font-montserrat-medium white-color'}>{item.title}</Text>

									<div className='wrapper-info-onFocus-mobile'>
										<Text className={'font-active-grotesk-normal p6 white-color description-mobile'}>
											<span>
												{translate('totalDistance')}
												&nbsp;:&nbsp;&nbsp;
												<span className='size-span-info-mobile'>{item.total_distance}</span>
											</span>
											<span>
												{translate('stages')}
												&nbsp;:&nbsp;&nbsp;
												<span className='size-span-info-mobile'>{item.stages_count}</span>
											</span>

											{item.min_duration ? (
												<>
													<span>
														{translate('duration')}
														&nbsp;:&nbsp;&nbsp;
														<span className='size-span-info-mobile'>
															{item.min_duration}&nbsp;-&nbsp;{item.max_duration}
															&nbsp;&nbsp;
															{translate('hours')}
														</span>
													</span>
												</>
											) : null}
										</Text>
									</div>
								</div>

								<div className='region-map-wrapper-mobile'>
									<SvgRegion s_types={item.map_style} />
								</div>
							</div>
						</div>
					</CustomLink>
				</div>
			);
		});
	});

	return (
		<MobileHomeRegionStyle>
			<Container
				full
				isSection>
				<div className='title-wrapper-region-mobile'>
					<ScrollActive
						animTitle
						allRefs={titleRef}>
						<Text
							ref={titleRef}
							tag={'h2'}
							className={'uppercase h2 font-montserrat-medium blue-1000-color uppercase opacity-0 title-regions-mobile'}
							text={title}
						/>
					</ScrollActive>
				</div>

				<div className='mobile-wrapper-regions'>{everRegion}</div>
			</Container>
		</MobileHomeRegionStyle>
	);
};

export default withLanguageContext(MobileHomeRegion, ['translate']);
