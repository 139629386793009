import { useEffect, useMemo, useRef } from 'react';
import gsap from 'gsap';

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Style
import SliderHomeRegionStyle from './style';

//* Components
import SvgRegion from './SvgRegion';
import Text from '@/components/global/Text';
import MobileHomeRegion from './MobileHomeRegion';
import CustomImage from '@/components/global/Image';
import Container from '@/components/global/Container';
import CustomLink from '@/components/global/CustomLink';
import ScrollActive from '@/components/global/ScrollActive';

const SliderHomeRegion = ({ winWidth, data, translate, title = 'exploreTheRegions', globalData }) => {
	//! Data
	data = globalData.regions;

	//! Check
	const isMobile = useMemo(() => {
		return winWidth < 1280;
	}, [winWidth]);

	//! Refs
	const sliderRefWrapper = useRef();
	const slidRef = data.map((item) => useRef(null));
	const wrapperRef = useRef();
	const titleRef = useRef();
	const progressBar = useRef();
	const progressWrapper = useRef();

	//! Parameter slide
	let sizeSlid;
	let sizeSlider;
	let lastSlide;
	let sliderType;

	//! Current Animation
	let allClassNamePosition = ['right', 'center', 'left'];
	let paddingDistance;
	let gapDistance;
	let currentWidthSlide;

	//! This option is control width grow
	let sliderWidthSize = 1.5;

	//! Drag no hover
	let isDrag = true;
	let dragIdMouseMove;

	//! Item blocked
	let firstLeftItemBlocked = 3;

	//! progress corected
	let progressCorected = false;

	//! Control options
	useEffect(() => {
		if (!isMobile) {
			paddingDistance = parseInt(getComputedStyle(wrapperRef.current).getPropertyValue('padding-left'));
			gapDistance = parseInt(getComputedStyle(wrapperRef.current.children[0]).getPropertyValue('gap'));
			currentWidthSlide = parseInt(getComputedStyle(slidRef[0]?.current).getPropertyValue('width'));
		}
	}, [winWidth]);

	//! slide create
	const everSlide = useMemo(() => {
		return data.map((item, i) => {
			let classNameIndividual = '';

			if (i % 3 === 1) {
				classNameIndividual = 'center';
			} else if (i % 3 === 2) {
				classNameIndividual = 'left';
			} else if (i % 3 === 0) {
				classNameIndividual = 'right';
			}

			return (
				<CustomLink
					key={i}
					url={`${config.routes.regions.path}${item.slug}`}>
					<div
						id={i}
						ref={slidRef[i]}
						slider-type={i !== 3 ? classNameIndividual : ''}
						onMouseEnter={swiperItemMouseEnter.bind(this, slidRef[i])}
						onMouseLeave={swiperItemMouseLeave.bind(this, slidRef[i])}
						className='wrapperItemSlider'>
						<div className='wrapper-item-slider'>
							<CustomImage
								src={item.featured_image.src}
								className={`everSlide ${classNameIndividual}`}
							/>

							<div className='wrapper-info'>
								<div className='title-wrapper'>
									<Text className={'h5 font-montserrat-medium white-color'}>{item.title}</Text>

									<div className='wrapper-info-onFocus'>
										<Text className={'font-active-grotesk-normal p6 white-color description'}>
											{translate('totalDistance')}
											&nbsp;:&nbsp;
											<span className='size-span-info'>{item.total_distance}</span>
											&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
											{translate('stages')}
											&nbsp;:&nbsp;
											<span className='size-span-info'>{item.stages_count}</span>
											{item.min_duration ? (
												<>
													&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
													{translate('duration')}
													&nbsp;:&nbsp;
													<span className='size-span-info'>
														{item.min_duration}-{item.max_duration}
														&nbsp;
														{translate('hours')}
													</span>
												</>
											) : null}
										</Text>
									</div>
								</div>

								<div className='region-map-wrapper'>
									<SvgRegion
										s_types={item.map_style}
										id={`wrapper-${i}`}
										className={item.title}
									/>
								</div>
							</div>
						</div>
					</div>
				</CustomLink>
			);
		});
	}, [data]);

	//! mouse enter
	function swiperItemMouseEnter(ref) {
		let individualElementRegion = ref.current.querySelector('.region-individual-present');
		individualElementRegion.classList.remove('opacity-1');

		individualElementRegion.classList.add('opacity-0');

		if (isDrag) {
			//! logic anim opened
			sliderType = ref.current.getAttribute('slider-type');

			//! logic inner-animation
			if (sliderType != 'null' && ref.current.id != firstLeftItemBlocked) {
				let wrapperInfo = ref.current.querySelector('.title-wrapper');
				let sizeInfoTransform = wrapperInfo.querySelector('.wrapper-info-onFocus');

				wrapperInfo.classList.add('hovered-title-wrapper');
				sizeInfoTransform.classList.add('hovered-wrapper-info-onFocus');

				//! individual regionsStyle
				let regionMap = ref.current.querySelector('.region-map');

				regionMap.classList.add('hovered-region-map');

				[...regionMap.children].forEach((item, i) => {
					item.classList.add('hovered-regions-item');
				});
			}

			//! left-logic
			if (sliderType === 'left') {
				gsap.fromTo(
					ref.current.children,
					{ left: 'auto' },
					{
						ease: 'power2.out',
						right: 0,
						width: currentWidthSlide * sliderWidthSize,
					}
				);

				let newArray = slidRef.slice(0, parseInt(ref.current.id));

				if (newArray.length > 0) {
					newArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: (currentWidthSlide * sliderWidthSize - currentWidthSlide) * -1,
						});
					});
				}
			}

			//! right-logic
			if (sliderType === 'right') {
				gsap.to(ref.current.children, {
					ease: 'power2.out',
					left: '0',
					width: currentWidthSlide * sliderWidthSize,
				});

				let newArray = slidRef.slice(parseInt(ref.current.id) + 1);

				if (newArray.length > 0) {
					newArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: currentWidthSlide * sliderWidthSize - currentWidthSlide,
						});
					});
				}
			}

			//! center-logic
			if (sliderType === 'center') {
				gsap.to(ref.current.children, {
					ease: 'power2.out',
					left: `${Math.floor((currentWidthSlide * sliderWidthSize - currentWidthSlide) / -2)}`,
					width: currentWidthSlide * sliderWidthSize,
				});

				let newRArray = slidRef.slice(parseInt(ref.current.id) + 1);

				if (newRArray.length > 0) {
					newRArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: `${Math.ceil((currentWidthSlide * sliderWidthSize - currentWidthSlide) / 2)}`,
						});
					});
				}

				let newLArray = slidRef.slice(0, parseInt(ref.current.id));

				if (newLArray.length > 0) {
					newLArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: `${Math.floor((currentWidthSlide * sliderWidthSize - currentWidthSlide) / -2)}`,
						});
					});
				}
			}

			dragIdMouseMove = ref.current.id;
		}

		lastSlide = ref.current.id;
	}

	//! mouse move
	function swiperItemMouseLeave(ref) {
		let individualElementRegion = ref.current.querySelector('.region-individual-present');
		individualElementRegion.classList.remove('opacity-0');
		individualElementRegion.classList.add('opacity-1');

		if (isDrag) {
			if (lastSlide !== undefined) {
				let wrapperInfo = slidRef[lastSlide].current.querySelector('.title-wrapper');
				let sizeInfoTransform = wrapperInfo.querySelector('.wrapper-info-onFocus');

				wrapperInfo.classList.remove('hovered-title-wrapper');
				sizeInfoTransform.classList.remove('hovered-wrapper-info-onFocus');

				let regionMap = slidRef[lastSlide].current.querySelector('.region-map');

				regionMap.classList.remove('hovered-region-map');

				[...regionMap.children].forEach((item, i) => {
					item.classList.remove('hovered-regions-item');
				});
			}

			//! left-logic
			if (sliderType === 'left') {
				gsap.to(ref.current.children, {
					ease: 'power2.out',
					right: 0,
					width: currentWidthSlide,
				});

				let newArray = slidRef.slice(0, parseInt(ref.current.id));

				if (newArray.length > 0) {
					newArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: '0',
						});
					});
				}
			}

			//! right-logic
			if (sliderType === 'right') {
				gsap.to(ref.current.children, {
					ease: 'power2.out',
					left: '0',
					width: currentWidthSlide,
				});

				let newArray = slidRef.slice(parseInt(ref.current.id) + 1);

				if (newArray.length > 0) {
					newArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: '0',
						});
					});
				}
			}

			//! center-logic
			if (sliderType === 'center') {
				gsap.to(slidRef[lastSlide].current.children, {
					ease: 'power2.out',
					left: '0',
					width: currentWidthSlide,
				});

				let newRArray = slidRef.slice(parseInt(ref.current.id) + 1);

				if (newRArray.length > 0) {
					newRArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: '0',
						});
					});
				}

				let newLArray = slidRef.slice(0, parseInt(ref.current.id));

				if (newLArray.length > 0) {
					newLArray.map((item, i) => {
						gsap.to(item.current, {
							ease: 'power2.out',
							left: '0',
						});
					});
				}
			}
		}
	}

	//! remove and add className and attribute
	function removeClassName(item, reverse) {
		if (reverse) {
			let newClassNamePosition = ['left', 'center', 'right'];

			item.forEach((element, i) => {
				newClassNamePosition.forEach((className) => {
					if (element?.classList.contains(className)) {
						element?.classList.remove(className);
					}
				});

				if (i !== 3) {
					element?.classList.add(newClassNamePosition[i]);
				} else {
					firstLeftItemBlocked = -1;
				}
			});
		} else {
			item.forEach((element, i) => {
				allClassNamePosition.forEach((className) => {
					if (element?.classList.contains(className)) {
						element?.classList.remove(className);
					}
				});

				if (i !== 3) {
					element?.classList.add(allClassNamePosition[i]);
				} else {
					firstLeftItemBlocked = -1;
				}
			});
		}
	}

	//! horizontal scroll snapping and corrected animation
	useEffect(() => {
		if (!isMobile) {
			//! new option
			let horizontalSection = document.querySelector('.all-wrapper');

			let sections = gsap.utils.toArray('.wrapperItemSlider');
			let widths = [];

			sections.forEach((section) => {
				widths.push(section.offsetWidth);
			});

			let totalWidth = horizontalSection?.scrollWidth - window.innerWidth;
			let snappingContainer = [0];
			let prev = paddingDistance + gapDistance + 1;

			widths.forEach((width, i) => {
				let current = (prev + width) / totalWidth;
				prev += width + gapDistance;
				snappingContainer.push(current);

				// snappingContainer.push(current.toFixed(3) * 1);
			});

			snappingContainer = snappingContainer.filter((item) => item < 1);
			snappingContainer.push(1);
			//!

			snappingContainer = snappingContainer.filter((item) => item < 1);
			snappingContainer.push(1);

			gsap.to('.all-wrapper', {
				x: () => horizontalSection.scrollWidth * -1,
				xPercent: 100,
				ease: 'none',

				scrollTrigger: {
					trigger: '.all-wrapper',
					start: 'center center',
					end: `bottom+=100% center`,
					pin: '.wrapper-other-template',
					scrub: true,
					ease: 'none',
					snap: {
						duration: 0.6,
						delay: 0,
						snapTo: snappingContainer,
						ease: 'power2.out',
					},

					onUpdate: function (self) {
						let horizontalSection = document.querySelector('.all-wrapper');

						snappingContainer.forEach((item, index) => {
							let vurentProgressBarDecimal = 1800 / horizontalSection?.scrollWidth / horizontalSection?.scrollWidth;
							// if (self.progress.toFixed(3) * 1 === item) {
							if ((self.progress + vurentProgressBarDecimal).toFixed(2) === item.toFixed(2)) {
								progressCorected = true;
								let allRefsPerPage = [];

								for (let ind = 0; ind < 4; ind++) {
									let translateAttributeElement = slidRef[index + ind]?.current;

									allRefsPerPage[ind] = translateAttributeElement?.querySelector('img').offsetParent;

									if (ind == 3) {
										translateAttributeElement?.setAttribute('slider-type', `null`);
									} else {
										translateAttributeElement?.setAttribute('slider-type', `${allClassNamePosition[ind]}`);
									}
								}
								removeClassName(allRefsPerPage);

								if (progressBar.current != null && progressBar.current !== undefined) {
									if (index === 2) {
										gsap.to(progressBar.current, {
											left: 'auto',
											right: 0,
											delay: 0,
											duration: 0.2,
										}).then(() => {
											progressCorected = false;
										});
									} else {
										gsap.to(progressBar.current, {
											left: `${((progressWrapper.current?.children[1].clientWidth * 100) / (progressWrapper.current?.clientWidth - 4 * 4)) * index}%`,
											delay: 0,
											duration: 0.2,
										}).then(() => {
											progressCorected = false;
										});
									}
								}
							} else {
								if (!progressCorected) {
									// gsap.to(progressBar.current, {
									// 	delay: 0,
									// 	duration: 0,
									// 	left: `calc(${(((progressWrapper.current?.clientWidth - progressBar.current?.clientWidth) * 100) / progressWrapper.current?.clientWidth) * self.progress}%`,
									// });
								}
							}
						});
					},

					invalidateOnRefresh: true,
				},
			});
		}
	}, [isMobile]);

	return !isMobile ? (
		<SliderHomeRegionStyle
			className='boundsSlider'
			$$sliderLength={data.length}>
			<Container>
				<div className='title-wrapper-region'>
					<ScrollActive
						animTitle
						allRefs={titleRef}>
						<Text
							ref={titleRef}
							tag={'h2'}
							className={'uppercase h2 font-montserrat-medium blue-1000-color uppercase opacity-0 title-regions'}
							text={title}
						/>
					</ScrollActive>
					<div
						ref={progressWrapper}
						className='progress-region'>
						<div
							ref={progressBar}
							className='current-progress'
						/>
						{slidRef.map((item, i) => {
							return (
								<div
									key={i}
									className='every-progress'></div>
							);
						})}
					</div>
				</div>
			</Container>

			<Container
				ref={wrapperRef}
				className={`all-wrapper ${!isMobile ? 'transform-slider' : ''}`}
				isSection>
				<div
					ref={sliderRefWrapper}
					className='wrapperSlider'>
					{everSlide}
				</div>
			</Container>
		</SliderHomeRegionStyle>
	) : (
		<Container>
			<MobileHomeRegion data={data} />
		</Container>
	);
};

export default withDataContext(withLanguageContext(withUIContext(SliderHomeRegion, ['winWidth']), ['translate']), ['globalData']);
