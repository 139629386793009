'use client';

//* HOC's
import { withDataContext } from '@/context';

//* Components
import MapSection from './MapSection';
import HeroSection from './HeroSection';
import GuidesSection from './GuidesSection';
import AboutHomeSection from './AboutSection';
import SliderHomeRegion from './SliderHomeRegion';
import RelatedSection from '../news/RelatedSection';
import StagesSection from '../regions/individual/StagesSection';
import ExploreTheFullSection from '../regions/individual/ExploreTheFullSection';

//* Style
import HomePageStyle from '@/modules/home/HomePageStyle';

const HomeTemplate = ({ pageData, globalData }) => {
	return (
		<HomePageStyle>
			<HeroSection pageData={pageData.hero} />

			<AboutHomeSection
				isHome
				data={globalData.key_numbers_section}
				{...pageData.kpi}
			/>

			{pageData.stages.length > 0 ? (
				<>
					<div className='wrapper-other-template'>
						<MapSection pageData={pageData.trail_map} />

						<SliderHomeRegion />

						<StagesSection
							isTemplate
							data={pageData.stages}
						/>
					</div>

					<ExploreTheFullSection className={'explore-distance'} />
				</>
			) : (
				<div className='wrapper-other-template'>
					<MapSection pageData={pageData.trail_map} />

					<SliderHomeRegion />

					<StagesSection
						isTemplate
						data={pageData.stages}
					/>

					<ExploreTheFullSection />
				</div>
			)}

			<RelatedSection
				title={'news'}
				data={pageData.news}
			/>

			<GuidesSection data={pageData.trail_and_guides} />
		</HomePageStyle>
	);
};

export default withDataContext(HomeTemplate, ['globalData']);
