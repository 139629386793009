import { useMemo } from 'react';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import CustomImage from '@/components/global/Image';
import CustomLink from '@/components/global/CustomLink';

//* Style
import StageItemStyle from './style';

const StageItem = ({ className, categories, region, duration, elevation, title, slug, total_distance, featured_image, winWidth }) => {
    //! Check Responsive Size
    const viewBoxResponsive = useMemo(() => (winWidth < 768 ? 16 : winWidth >= 1920 && winWidth < 2560 ? 24 : winWidth >= 2560 ? 32 : 20), [winWidth]);
    const leftPathDResponsive = useMemo(() => (winWidth < 768 ? 'M16,16H0V0c0,8.83,7.16,16,16,16Z' : winWidth >= 1920 && winWidth < 2560 ? 'M24,24H0V0c0,13.25,10.74,24,24,24Z' : winWidth >= 2560 ? 'M32,32H0V0c0,17.67,14.32,32,32,32Z' : 'M20,20H0V0c0,11.04,8.95,20,20,20Z'), [winWidth]);
    const rightPathDResponsive = useMemo(() => (winWidth < 768 ? 'M0,16h16s0-16,0-16c0,8.83-7.16,16-16,16Z' : winWidth >= 1920 && winWidth < 2560 ? 'M0,24h24s0-24,0-24c0,13.25-10.74,24-24,24Z' : winWidth >= 2560 ? 'M0,32h32s0-32,0-32c0,17.67-14.32,32-32,32Z' : 'M0,20h20s0-20,0-20c0,11.04-8.95,20-20,20Z'), [winWidth]);

    return (
        <StageItemStyle className={className || ''}>
            <CustomLink url={`${config.routes.stages.path}/${slug}`}>
                <CustomImage
                    src={featured_image?.src}
                    alt={featured_image?.alt}
                />

                {region || categories?.length > 0 ? (
                    <div className='top-absolute-wrapper'>
                        <div className='top-absolute-inner-wrapper'>
                            {categories.map((cat, index) => (
                                <div key={index}>
                                    <Text className={`p p6 font-montserrat-medium blue-1000-color tag-title`}>{cat.name}</Text>
                                </div>
                            ))}

                            <div>
                                <Text className={`p p6 font-montserrat-medium blue-1000-color tag-title`}>{region.title}</Text>
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className='bottom-absolute-wrapper'>
                    <div className='bottom-absolute-inner-wrapper'>
                        <div className='bottom-absolute-inner-top-wrapper'>
                            <Text className={`h5 font-montserrat-medium blue-1000-color title`}>{title}</Text>
                        </div>

                        <div className='flex-end-wrapper'>
                            <div className='attr-block'>
                                <Text
                                    className={`p p6 font-active-grotesk-normal blue-1000-color`}
                                    text={'duration'}
                                />

                                <Text className={`p p3 font-active-grotesk-medium blue-1000-color`}>{duration}</Text>
                            </div>

                            <div className='attr-block'>
                                <Text
                                    className={`p p6 font-active-grotesk-normal blue-1000-color`}
                                    text={'distance'}
                                />

                                <Text className={`p p3 font-active-grotesk-medium blue-1000-color`}>{total_distance}</Text>
                            </div>

                            <div className='attr-block'>
                                <Text
                                    className={`p p6 font-active-grotesk-normal blue-1000-color`}
                                    text={'ascent'}
                                />

                                <Text className={`p p3 font-active-grotesk-medium blue-1000-color`}>{elevation}</Text>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='svg-background'>
                    <svg
                        id='left'
                        data-name='left'
                        xmlns='http://www.w3.org/2000/svg'
                        version='1.1'
                        viewBox={`0 0 ${viewBoxResponsive} ${viewBoxResponsive}`}>
                        <path
                            className='left'
                            strokeWidth={0}
                            fill='var(--whiteColor)'
                            d={leftPathDResponsive}
                        />
                    </svg>

                    <svg
                        id='right'
                        data-name='right'
                        xmlns='http://www.w3.org/2000/svg'
                        version='1.1'
                        viewBox={`0 0 ${viewBoxResponsive} ${viewBoxResponsive}`}>
                        <path
                            className='right'
                            strokeWidth={0}
                            fill='var(--whiteColor)'
                            d={rightPathDResponsive}
                        />
                    </svg>
                </div>
            </CustomLink>
        </StageItemStyle>
    );
};

export default withUIContext(StageItem, ['winWidth']);
