import styled from 'styled-components';

const SliderHomeRegionStyle = styled.section`
	--distanceSlideGap: var(--sp4x);
	--distancePaddingImage: 29vw;
	--titlePadding: var(--sp7x);
	--distancePaddingText: var(--sp3x);
	--borderRadius: var(--sp4x);
	--progressWidth: var(--sp2-5x);
	--distanceProgressGap: var(--sp0-5x);
	--progressHeight: var(--sp1x);

	--regionMapSize: var(--sp24x);
	--sizeSvgIndividual: var(--sp13x);
	--dsitanceIndividualSvg: var(--sp4x);

	max-width: 100vw;
	overflow: hidden;
	will-change: transform;

	.transform-slider {
		will-change: transform;
	}

	.title-wrapper-region {
		margin-bottom: var(--titleSectionDistance);
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.wrapperSlider {
		display: flex;
		flex-wrap: nowrap;
		gap: var(--distanceSlideGap);
		/* width: calc(${(props) => props.$$sliderLength}* var(--distancePaddingImage) + ${(props) => props.$$sliderLength} * var(--distanceSlideGap) + var(--contPaddingLR) - var(--distanceSlideGap)); */

		width: calc(${(props) => props.$$sliderLength}* var(--distancePaddingImage) + ${(props) => props.$$sliderLength - 1} * var(--distanceSlideGap) + var(--contPaddingLR));
	}

	.wrapperItemSlider {
		width: var(--distancePaddingImage);
		position: relative;
		padding-top: var(--distancePaddingImage);
		border-radius: var(--borderRadius);
	}

	.image-cont {
		width: 100%;
		padding-top: var(--distancePaddingImage);
		overflow: hidden;
		border-radius: var(--borderRadius);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: var(--neutral1000Color);
			opacity: 0.2;
		}

		img {
			border-radius: var(--borderRadius);
			transition: object-position calc(var(--trTime) * 1) ease-out;
			object-fit: cover;
		}
	}

	.wrapper-item-slider {
		position: absolute;
		top: 0;
		width: var(--distancePaddingImage);
		height: 100%;
		border-radius: var(--borderRadius);
	}

	.title-wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0 0 var(--titlePadding) var(--titlePadding);
		transform: translateY(calc(var(--lineHeightL) * var(--p4) + var(--distancePaddingText)));
		transition: all var(--trTime) ease-out;
		width: calc(100% - var(--titlePadding) * 2);
		padding-right: calc(var(--sizeSvgIndividual) + var(--dsitanceIndividualSvg));
	}

	.description {
		display: flex;
		align-items: center;
		white-space: nowrap;

		.size-span-info {
			font-size: var(--p4);
		}
	}

	.wrapper-info {
		position: relative;
		z-index: 2;
		height: inherit;
		top: 0;
		left: 0;
		transform: translateY(-100%);
		overflow: hidden;
	}

	.wrapper-info-onFocus {
		padding-top: var(--distancePaddingText);
		opacity: 0;
		transition: opacity var(--trTime) ease-out;
	}

	.region-map {
		position: absolute;
		right: 0;
		bottom: 0;
		width: var(--regionMapSize);
		height: var(--regionMapSize);
		margin-bottom: var(--titlePadding);
		margin-right: var(--titlePadding);
		transition: all var(--trTime);

		.region {
			opacity: 0;
			transition: opacity calc(var(--trTime) * 1) ease-out;
			/* fill: transparent; */
		}

		.current-region {
			opacity: 1 !important;
			fill: var(--whiteColor);
		}
	}

	.hovered-region-map {
		transform: translate(0px, 0px) scale(1) !important;
	}

	.hovered-regions-item {
		opacity: 1 !important;
	}

	.hovered-title-wrapper {
		transform: translateY(0);
	}

	.hovered-wrapper-info-onFocus {
		opacity: 1;
	}

	/* //!slider right */
	.right {
		img {
			object-position: center center;
		}
	}

	/* //!slider center */
	.center {
		img {
			object-position: center center;
		}
	}

	/* //!slider left */
	.left {
		img {
			object-position: center center;
		}
	}
	//! progress-bar
	.progress-region {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		gap: var(--distanceProgressGap);

		.current-progress {
			will-change: transform;
			position: absolute;
			left: 0;
			width: calc(100% - calc(var(--progressWidth) * 2) - calc(var(--distanceProgressGap) * 2));
			height: var(--progressHeight);
			display: flex;
			background-color: var(--blue1000Color);
			border-radius: var(--sp2-5x);
		}

		.every-progress {
			border-radius: var(--sp2-5x);
			width: var(--progressWidth);
			height: var(--progressHeight);
			background-color: var(--neutral100Color);
		}
	}
	.region-individual-present {
		position: absolute;
		bottom: 0;
		right: 0;
		transform: scale(1);
		transition: opacity 0.3s ease-out;
		width: var(--sizeSvgIndividual);
		margin-right: var(--titlePadding);
		margin-bottom: var(--titlePadding);
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--distanceSlideGap: var(--sp3x);
		--distancePaddingImage: 29.17vw;
		--distancePaddingText: var(--sp2-5x);
		--titlePadding: var(--sp5x);
		--borderRadius: var(--sp3x);
		--sizeSvgIndividual: var(--sp9x);
		--regionMapSize: var(--sp20x);
		--dsitanceIndividualSvg: var(--sp3x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--distanceSlideGap: var(--sp3x);
		--distancePaddingImage: 28.84vw;
		--distancePaddingText: var(--sp2x);
		--titlePadding: var(--sp4x);
		--borderRadius: var(--sp2-5x);
		--sizeSvgIndividual: var(--sp8x);
		--regionMapSize: var(--sp16x);
		--dsitanceIndividualSvg: var(--sp3x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--distanceSlideGap: var(--sp3x);
		--distancePaddingImage: 29.1vw;
		--distancePaddingText: var(--sp1x);
		--titlePadding: var(--sp3x);
		--borderRadius: var(--sp2-5x);
		--sizeSvgIndividual: var(--sp8x);
		--regionMapSize: var(--sp15x);
		--dsitanceIndividualSvg: var(--sp3x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--distanceSlideGap: var(--sp2-5x);
		--distancePaddingImage: 29.8vw;
		--distancePaddingText: var(--sp2x);
		--titlePadding: var(--sp3x);
		--borderRadius: var(--sp2-5x);
		--sizeSvgIndividual: var(--sp7x);
		--regionMapSize: var(--sp13x);
		--dsitanceIndividualSvg: var(--sp3x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--distanceSlideGap: var(--sp2x);
		--sizeSvgIndividual: var(--sp7x);
		--dsitanceIndividualSvg: var(--sp1x);

		display: none;
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceSlideGap: var(--sp2x);
		--sizeSvgIndividual: var(--sp7x);
		--dsitanceIndividualSvg: var(--sp1x);

		display: none;
	}
`;

export default SliderHomeRegionStyle;
