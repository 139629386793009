import styled from 'styled-components';

const HeroSectionStyle = styled.section`
	--imageVideoSize: calc(${(props) => Math.floor(props.$winWidth * (227 / 2564))}px + 100vh);

	--topTextInfo: var(--sp59x);
	/* --topSliderInfo: var(--sp23x); */
	--topSliderInfo: calc(${(props) => Math.floor(props.$winWidth * (227 / 2564))}px + var(--sp23x));

	will-change: transform;

	.wrapper-banner {
		overflow: hidden;
		position: relative;

		.every-hero-item {
			position: absolute;
			width: 100%;
			top: 0;
			opacity: 0;
			will-change: transform opacity;
			/* transition: var(--trTime) cubic-bezier(0, 0.49, 0.15, 1.06); */
			transition: var(--trTime) cubic-bezier(0, 0.26, 0.23, 1.1);

			&:first-child {
				position: relative;
				top: 0;
			}
		}

		.ever-inner-image-wrapper {
			position: relative;
		}

		.file-section {
			--fileHeight: var(--imageVideoSize);
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				z-index: 1;
				width: 100%;
				height: 150%;
				background-color: var(--neutral1000Color);
				opacity: 0.2;
			}

			img {
				backface-visibility: hidden;
				will-change: transform;
				object-fit: cover;
				height: 150% !important;
			}
		}
	}

	.text-wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		padding-left: var(--contPaddingLR);
		top: var(--topTextInfo);
		height: fit-content;

		.title {
			font-weight: 500;
			margin-bottom: var(--distanceTitle);
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.description {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.description-wrapper {
			padding-bottom: var(--distanceDescription);
		}
	}

	.slider-navigation {
		position: absolute;
		bottom: var(--topSliderInfo);
		right: 0;
		padding-right: var(--contPaddingLR);
		display: flex;
		align-items: center;
		gap: var(--sp3x);
		height: calc(var(--p4) * var(--lineHeightL) * 1.3);

		.scale-hover {
			display: flex;
			justify-content: center;
			align-items: center;
			transition: transform var(--trTime) ease-out;
		}

		i {
			font-size: var(--p2);
			cursor: pointer;
			color: var(--orange1000Color);
		}
	}

	.wrapper-svg {
		position: absolute;
		z-index: 99;
		bottom: 0;
		left: 0;
		width: calc(100% + 3px);

		.inner-wrapper-svg {
			position: relative;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			.whiteFon {
				width: 100%;
			}

			.vectorFon {
				z-index: -1;
				bottom: -100%;
				width: 100%;
				position: absolute;
			}
		}
	}

	.wrapper-button-animation {
		will-change: transform;
		position: relative;
		height: fit-content;
		width: fit-content;

		.button-wrapper {
			z-index: 100;
		}
	}

	.div-scaled-button {
		position: absolute;
		top: 0;
		z-index: 0;
		transform: scaleX(1.2);
		width: 100%;
		height: 100%;
	}

	.navigation-wrapper-number {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		justify-items: center;
	}

	@media (hover: hover) {
		.scale-hover {
			&:hover {
				transform: scale(1.3);
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--topTextInfo: var(--sp44x);
		--topSliderInfo: var(--sp25x);
		--imageVideoSize: 60.5%;
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--topTextInfo: var(--sp29x);
		--topSliderInfo: var(--sp25x);
		--imageVideoSize: 60.5%;
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--topTextInfo: var(--sp26x);
		--topSliderInfo: var(--sp22x);
		--imageVideoSize: 60.5%;
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--imageVideoSize: 60.5%;
		--topTextInfo: var(--sp26x);
		--topSliderInfo: var(--sp22x);
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeX}) {
		--imageVideoSize: 70.5%;
		--topTextInfo: var(--sp26x);
		--topSliderInfo: var(--sp22x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeXMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--imageVideoSize: 102%;
		--topTextInfo: var(--sp26x);
		--topSliderInfo: var(--sp22x);

		.slider-navigation {
			left: 0;
			padding-right: 0;
			padding-left: var(--contPaddingLR);
		}

		.text-wrapper {
			padding: 0 var(--contPaddingLR);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--imageVideoSize: 171%;
		--topTextInfo: var(--sp26x);
		--topSliderInfo: var(--sp17x);

		.slider-navigation {
			left: 0;
			padding-right: 0;
			padding-left: var(--contPaddingLR);
		}

		.text-wrapper {
			padding: 0 var(--contPaddingLR);
		}
	}
`;

export default HeroSectionStyle;
