import styled from 'styled-components';

const GuidesSectionStyle = styled.section`
	--distanceEveryItem: var(--sp3x);
	--borderRadius: var(--sp4x);
	--distanceTitleDowland: var(--sp4x);
	--paddingContent: var(--sp4x);
	--lottieSize: var(--sp4x);

	.right-section {
		.title {
			margin-bottom: var(--distanceTitle);
		}

		.description {
			margin-bottom: var(--distanceDescription);
		}
	}

	.left-section {
		display: flex;
		flex-direction: column;
		gap: var(--distanceEveryItem);

		.every-guide {
			border-radius: var(--borderRadius);
			padding: var(--paddingContent);
			background-color: var(--surfaceColor);

			.dowland-button {
				background-color: transparent;

				button {
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						text-transform: uppercase;
						font-size: var(--p5);
					}
					.lottie-wrap {
						width: var(--lottieSize);
					}
				}
			}

			.dowland-wrapper {
				display: flex;
				justify-content: end;
				margin-top: var(--distanceTitleDowland);
			}
		}
	}
	.button-wrapper {
		position: relative;
		width: fit-content;
		.button-real {
			z-index: 100;
		}
	}

	.div-scaled-button {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		z-index: 0;
		transform: scaleX(1.2);
		width: 100%;
		height: 100%;
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--distanceEveryItem: var(--sp2x);
		--borderRadius: var(--sp3x);
		--distanceTitleDowland: var(--sp3x);
		--paddingContent: var(--sp3x);
		--lottieSize: var(--sp3x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--distanceEveryItem: var(--sp2x);
		--borderRadius: var(--sp3x);
		--distanceTitleDowland: var(--sp2x);
		--paddingContent: var(--sp3x);
		--lottieSize: var(--sp3x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--distanceEveryItem: var(--sp2x);
		--borderRadius: var(--sp3x);
		--distanceTitleDowland: var(--sp1-5x);
		--paddingContent: var(--sp2-5x);
		--lottieSize: 18px;
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--distanceEveryItem: var(--sp2x);
		--borderRadius: var(--sp3x);
		--distanceTitleDowland: var(--sp1-5x);
		--paddingContent: var(--sp2-5x);
		--lottieSize: 18px;
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--distanceEveryItem: var(--sp2x);
		--borderRadius: var(--sp3x);
		--distanceTitleDowland: var(--sp1-5x);
		--paddingContent: var(--sp2-5x);
		--lottieSize: 18px;

		.right-section {
			padding-bottom: var(--sp5x);
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceEveryItem: var(--sp2x);
		--borderRadius: var(--sp3x);
		--distanceTitleDowland: var(--sp1-5x);
		--paddingContent: var(--sp2-5x);
		--lottieSize: 18px;

		.right-section {
			padding-bottom: var(--sp4x);
		}
	}
`;

export default GuidesSectionStyle;
