import styled from 'styled-components';

const MobileHomeRegionStyle = styled.section`
	--imagePadding: 100%;
	--titlePadding: var(--sp2x);
	--distancePaddingText: var(--sp3x);
	--border-radius: var(--sp2-5x);

	.mobile-wrapper-regions {
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		gap: var(--titlePadding);
	}

	.title-wrapper-region-mobile {
		margin-bottom: var(--titleSectionDistance);
	}

	.wrapperItemSlider-mobile {
		width: calc(50% - var(--titlePadding) / 2);
		position: relative;
		overflow: hidden;
	}

	.wrapper-info-onFocus-mobile {
		padding-top: var(--titlePadding);
	}

	.image-cont {
		--proportion: var(--imagePadding);

		border-radius: var(--border-radius);
		overflow: hidden;

		img {
			object-fit: cover;
		}
	}

	.title-wrapper-mobile {
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0 0 var(--titlePadding) var(--titlePadding);
		padding-right: calc(var(--titlePadding) / 2 + var(--sp7x));
		width: calc(100% - var(--titlePadding) * 2);
	}

	.description-mobile {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		white-space: nowrap;

		.size-span-info-mobile {
			font-size: var(--p4);
		}
	}

	.wrapper-info-mobile {
		width: 100%;
		position: absolute;
		height: inherit;
		top: 0;
		left: 0;
		overflow: hidden;
		height: 100%;
	}

	.region-map-wrapper-mobile {
		position: absolute;
		right: 0;
		bottom: 0;
		width: var(--sp7x);

		/* height: 100px; */
		margin-bottom: var(--titlePadding);
		margin-right: var(--titlePadding);
		transition: all var(--trTime);
	}

	.region-individual-present {
		width: var(--sp7x);
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--border-radius: var(--sp2x);

		.title-wrapper-mobile {
			/* padding-right: 0; */
		}

		.wrapperItemSlider-mobile {
			width: 100%;
		}
	}
`;

export default MobileHomeRegionStyle;
