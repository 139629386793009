import styled from 'styled-components';

const StagesSectionStyle = styled.section`
	--stagesBottomWrapperMTop: var(--sp11x);
	will-change: transform;

	.stages-bottom-wrapper {
		margin-top: var(--stagesBottomWrapperMTop);

		.row {
			row-gap: var(--stagesBottomWrapperMTop);
		}
	}

	.btn-lottie-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--stagesBottomWrapperMTop: var(--sp8x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--stagesBottomWrapperMTop: var(--sp7x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--stagesBottomWrapperMTop: var(--sp6x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--stagesBottomWrapperMTop: var(--sp6x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--stagesBottomWrapperMTop: var(--sp5x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--stagesBottomWrapperMTop: var(--sp4x);

		.btn-lottie-wrapper {
			justify-content: flex-start;
			margin-top: var(--sp4x);
		}
	}
`;

export default StagesSectionStyle;
