import styled from 'styled-components';

const HomePageStyle = styled.section`
	--distanceTitle: var(--sp4x);
	--distanceDescription: var(--sp8x);
	--staticSectionPadB: 0;

	--borderRadiusStaticSection: var(--sp8x);
	--staticSectionPadT: var(--sp16x);
	--staticSectionPadLR: var(--sp24x);
	--titleSectionDistance: var(--sp11x);

	--distanceKpiHome: var(--sp25x);

	will-change: scroll-position;

	.explore-distance {
		margin-top: var(--sectionDistance);
	}

	.change-class-name-kpi {
		margin-bottom: var(--distanceKpiHome) !important;
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--distanceTitle: var(--sp3x);
		--distanceDescription: var(--sp6x);

		--staticSectionPadT: var(--sp13x);
		--staticSectionPadLR: var(--sp18x);
		--borderRadiusStaticSection: var(--sp6x);
		--titleSectionDistance: var(--sp8x);

		--distanceKpiHome: var(--sp20x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--distanceTitle: var(--sp2x);
		--distanceDescription: var(--sp5x);

		--staticSectionPadT: var(--sp10x);
		--staticSectionPadLR: var(--sp15x);
		--borderRadiusStaticSection: var(--sp5x);
		--titleSectionDistance: var(--sp7x);

		--distanceKpiHome: var(--sp19x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--distanceTitle: var(--sp2x);
		--distanceDescription: var(--sp4x);

		--staticSectionPadT: var(--sp8x);
		--staticSectionPadLR: var(--sp13x);
		--borderRadiusStaticSection: var(--sp5x);
		--titleSectionDistance: var(--sp6x);

		--distanceKpiHome: var(--sp17x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--distanceTitle: var(--sp2x);
		--distanceDescription: var(--sp4x);

		--staticSectionPadT: var(--sp8x);
		--staticSectionPadLR: var(--sp13x);
		--borderRadiusStaticSection: var(--sp5x);
		--titleSectionDistance: var(--sp6x);

		--distanceKpiHome: var(--sp16x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--distanceTitle: var(--sp2x);
		--distanceDescription: var(--sp4x);

		--staticSectionPadT: var(--sp8x);
		--staticSectionPadB: var(--sp8x);
		--staticSectionPadLR: var(--sp8x);
		--borderRadiusStaticSection: var(--sp5x);
		--titleSectionDistance: var(--sp5x);

		--distanceKpiHome: var(--sp13x);
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceTitle: var(--sp2x);
		--distanceDescription: var(--sp4x);

		--staticSectionPadT: var(--sp6x);
		--staticSectionPadB: var(--sp6x);
		--staticSectionPadLR: var(--sp3x);
		--borderRadiusStaticSection: var(--sp3x);
		--titleSectionDistance: var(--sp4x);

		--distanceKpiHome: var(--sp10x);
	}
`;

export default HomePageStyle;
