//* Style
import StagesSectionStyle from './style';

//* Lottie
import arrow_lottie_file from '@/lottieFiles/arrow.json';

//* Components
import StageItem from '../StageItem';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';
import { useRef } from 'react';

const StagesSection = ({ title = 'hikingDestinations', isTemplate, btnUrl = false, data }) => {
	//! Refs
	const titleRef = useRef();

	return data?.length > 0 ? (
		<StagesSectionStyle>
			<Container
				isSection
				className={'stages-wrapper'}>
				<Container
					row
					full
					className={'stages-top-wrapper'}>
					<div className='col-6 col-t-9 col-m-12'>
						<ScrollActive
							animTitle
							allRefs={titleRef}>
							<Text
								tag={'h2'}
								className={'h2 font-montserrat-medium blue-1000-color uppercase opacity-0'}
								text={title}
								ref={titleRef}
							/>
						</ScrollActive>
					</div>
					{isTemplate ? null : (
						<div className='col-6 col-t-3 col-m-12 btn-lottie-wrapper'>
							{btnUrl ? (
								<Button
									url={btnUrl}
									text={'newsMore'}
									btnType={'lottie'}
									className={'arrow'}
									lottieFiles={arrow_lottie_file}
								/>
							) : null}
						</div>
					)}
				</Container>

				<Container
					row
					full
					className={'stages-bottom-wrapper'}>
					{data.map((item, index) => (
						<div
							key={index}
							className='col-4 col-t-6 col-m-12'>
							<StageItem {...item} />
						</div>
					))}
				</Container>
			</Container>
		</StagesSectionStyle>
	) : null;
};

export default StagesSection;
